* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body {
    background-color: #ede7e2;
}

.text-danger {
    color: #ff0000 !important;
    text-align: left;
}

.copyright {
    /* display: none; */
}

.heart {
    display: none;
}

#main-wrapper:after,
#main-wrapper:before {
    display: none;
}

.dlab-side-menu {
    display: none;
}

.header {
    padding-right: 0px;
}

.nav-header {
    border-top-right-radius: 0px !important;
    background-color: #332d28;
    padding: 0px 20px;
}

.nav-header a {
    border-bottom: 1px solid gray;
}

.dlabnav {
    background-color: #332d28 !important;
    border-bottom-right-radius: 0px !important;
}

.header .header-content:after {
    height: 1px;
    background-color: #332d28;
    left: 20px;
    width: 96.5%;
}

.content-body {
    padding-right: 0px;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
    padding-top: 1rem !important;
}

/*******************hamburger************/
.nav-control {
    display: none;
}

.header .header-content {
    padding-left: 35px;
}

/***************side-menu**************/
.nav-header .brand-logo {
    padding: 0px;
}

.brand-logo img {
    width: 100%;
}

.dlabnav .metismenu>li {
    padding: 0px !important;
}

.dlabnav .metismenu>li>a {
    border-radius: 0px !important;
}

.dlabnav .metismenu>li.mm-active>a {
    background-color: #514b46;
    box-shadow: none;
}

/*****************start-nav-header******************/
.nav-head-notify-icon-view {
    width: 110px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
}

.nav-head-notify-icon-view button {
    border: none;
    background-color: transparent;
}

.nav-head-notify-icon-view button i {
    font-size: 20px;
    color: #ff9a55;
}

.nav-head-power-btn-view {
    padding-left: 10px;
}

.nav-head-power-btn-view button {
    border: none;
    background-color: transparent;
}

.nav-head-power-btn-view button i {
    font-size: 25px !important;
    color: #ff9a55;
}

.header-right .search-area {
    width: 11.625rem;
}

.search-area input {
    text-align: right;
}

.header-right .search-area .form-control {
    padding-right: 0px;
    border-radius: 50px;
}

.header-right .search-area .input-group-text {
    padding: 0px;
    border-radius: 50px;
}

.header-right .search-area .input-group-text i {
    color: #000000;
}

.add-invitation {
    margin-left: 10px;
}

.add-invitation a {
    background-color: #ff9a55;
    color: #ffffff;
    border-radius: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    line-height: 0px;
    font-size: 13px;
    font-weight: 500;
}

.add-invitation a i {
    font-size: 26px;
    padding-left: 5px;
}

.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
    color: #ff9a55;
}

/******************Custom-nav-scrollbar******************/
.nav-profile,
.mm-wrapper,
.metismenu {
    direction: ltr
}

.custom-nav-scrollbar {
    height: 100%;
    overflow-y: overlay;
    direction: rtl;
}

/* Designing for scroll-bar */
.custom-nav-scrollbar::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.custom-nav-scrollbar ::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}

/* Handle */
.custom-nav-scrollbar::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 5px;
}

/* Handle on hover */
.custom-nav-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ede7e2;
}

/******************Account******************/
.title-body {
    width: 100%;
    /* margin-bottom: 20px; */
}

.title-body h3 {
    margin: 0px;
}

.title-body p {
    margin: 0px;
    color: #332d28;
}

.user-content-view {
    width: 100%;
    border-bottom: 1px solid;
    padding-bottom: 30px;
    padding-top: 20px;
}

.user-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-card-img img {
    width: 55px;
    height: 55px;
    border-radius: 10px;
}

.user-card-content {
    margin-left: 10px;
}

.user-card-content h4 {
    margin: 0px;
    font-size: 13px;
}

.user-card-content p {
    margin: 0px;
    font-size: 12px;
    line-height: 1.3;
}

.add-user-card {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    background-color: #ff9a55;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-user-card i {
    font-size: 36px;
    color: #ffffff;
}

.add-user-content h2 {
    font-size: 18px;
    padding-left: 10px;
    text-transform: capitalize;
    margin: 0px;
    font-weight: 400;
}

.subscription-content-view {
    width: 100%;
    margin-top: 20px;
}

.organization-info-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 20px 70px 20px;
}

.organization-info-title-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    padding-bottom: 2px;
}

.organization-info-title-view h3 {
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    color: #332d28;
    padding-bottom: 2px;
    margin-top: 10px;
}

.organization-info-title-view i {
    font-size: 16px;
}

.organization-info-form-view {
    width: 100%;
    margin-top: 15px;
    padding: 0px 8px;
}

.organization-info-form-group {
    margin-bottom: 6px;
}

.organization-info-form-group label {
    font-size: 10px;
}

.organization-info-form-group input:hover {
    background-color: #f2f2f2;
}

.organization-info-form-group input {
    background-color: #f2f2f2;
    border: none;
    height: 32px;
    font-size: 12px;
}

.organization-info-form-group textarea {
    background-color: #f2f2f2;
    border: none;
    /* height: 60px; */
    font-size: 12px;
}

.subscribtion-card {
    background-color: #514b46;
    border-radius: 10px;
    padding: 30px;
}

.subscribtion-card-title-view {
    border-bottom: 1px solid #ffffff;
}

.subscribtion-card-title-view h3 {
    margin: 0px;
    color: #ffffff;
    font-weight: 400;
    padding-bottom: 2px;
}

.subscribtion-content {
    margin-top: 25px;
}

.subscribtion-plane-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px 20px 20px 20px;
}

.subscribtion-plane-left {
    position: relative;
}

.subscribtion-plane-left h3 {
    margin: 0px;
    color: #332d28;
    font-size: 18px;
}

.subscribtion-plane-left p {
    margin: 0px;
    color: #332d28;
    font-size: 14px;
    font-weight: 400;
}

.subscribtion-plane-btn {
    border: none;
    padding: 4px 24px;
    background-color: #ff9a55;
    color: #f2f2f2;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: -43px;
    left: 0px;
}

.subscribtion-expired-btn {
    border: none;
    padding: 4px 15px;
    background-color: #ff9a55;
    color: #f2f2f2;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}

.subscribtion-plane-right h3 {
    margin: 0px;
    color: #332d28;
    font-size: 20px;
}

.subscribtion-plane-dis p {
    margin: 0px;
    font-size: 13px;
    color: #b2b2b2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.subscribtion-plane-btn-content {
    display: flex;
    flex-wrap: wrap;
}

.subscribtion-plane-btn-content button {
    border: none;
    background-color: #ffffff;
    width: 140px;
    height: 40px;
    font-size: 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin: 6px 12px 6px 0px;
}

.account-setting-content {
    margin-top: 10px;
    margin-bottom: 30px;
}

.account-setting-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px 30px 20px 30px;
    height: 260px;
}

.account-setting-card h4 {
    margin: 0px;
    font-size: 18px;
    color: #332d28;
}

.account-setting-option {
    margin-top: 5px;
}

.account-setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe1cc;
    border-radius: 6px;
    margin: 10px 0px;
    height: 35px;
    padding: 0px 15px;
}

.account-setting-item p {
    margin: 0px;
    font-size: 11px;
}

/******************start-login-section******************/
.login-section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.login-logo-header {
    width: 100%;
    padding: 20px 0px 0px 20px;
    display: flex;
    align-items: center;
}

.login-logo-header img {
    width: 40px;
}

.login-logo-header h2 {
    margin: 0px;
    color: #000000;
    font-weight: 900;
    font-size: 26px;
    padding-left: 4px;
}

.login-form-section {
    width: 350px;
}

.login-form-section h3 {
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
    font-size: 24px;
    font-family: system-ui;
}

.view-form-group {
    border: 1.5px solid #b7b7b7;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.form-group-icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-group-icon i.fa {
    font-size: 20px;
}

.cus-form-control {
    width: 100%;
    border: none;
    height: 45px;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 600;
    background: none;
    outline: none;
}

.submit-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #000000;
    margin-top: 30px;
    margin-bottom: 20px;
}

.auth-back-btn {
    border-radius: 6px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #332d28;
    background-color: transparent;
    border: 2px solid #332d28;
    margin-right: 30px;

}

.forgot-password-view {
    padding-top: 0px;
    text-align: center;
}

.forgot-password-view a {
    text-decoration: underline;
    font-weight: 500;
}

.checkbox-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.create-account-text {
    color: #000000;
    font-weight: 600;
}

.foegot-pass-text {
    color: #676a6c;
}

.login-footer {
    width: 100%;
    text-align: center;
    background-color: #bcbcbc;
}

.login-footer p {
    color: #676a6c;
    font-size: 14px;
    margin: 0px;
    padding: 10px;
    font-weight: 400;
}

.login-footer p a {
    color: #676a6c;
}


/******************register-section******************/
.resister-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.register-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.radio-form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.register-form-section {
    width: 50%;
    margin: 50px 0px;
}

.form-section {
    width: 350px;
    margin: auto;
}

.border-buttom-1 {
    width: 100%;
    height: 1px;
    background-color: #e5e6e7;
    margin: 30px 0px 30px 0px;
}

.register-form-section h3 {
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
    font-size: 26px;
    font-family: system-ui;
}

.radio-form-label span {
    font-size: 15px;
    color: #4e4d4d;
}

.custom-form-control {
    height: 45px !important;
    border-radius: 6px;
    font-weight: 600;
}

.continue-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #000000;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 15px;
}

.cap-img {
    width: 120px;
}

.cus-form-label {
    font-weight: 800;
}

.otp-form-section {
    width: 280px;
}

.otp-form-section h3 {
    font-size: 36px;
    text-align: center;
    color: #000000;
    font-weight: 800;
}

.otp-form-section p {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #a39898;
    padding-bottom: 20px;
}

.otp-form-section h4 {
    font-size: 15px;
    text-align: center;
    color: #4c4c4c;
    padding-bottom: 10px;
}

.otp-form-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 30px;
}

.otp-form-control {
    width: 40px;
    height: 40px;
    border: 2px solid #e5e6e7;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    padding-left: 13px;
}

.otp-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #383838;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-btn span {
    margin-left: 5px;
}

.form-group-pws {
    position: relative;
}

.input-control-pws {
    padding-right: 40px !important;
}

.pws-icon-view {
    position: absolute;
    top: 8px;
    right: 15px;
}

.pws-icon-view i {
    font-size: 20px;
}

.otp-btn:disabled {
    opacity: 0.6;
}

/**********************Individual-signin-section**************************/
.form-control:focus {
    border-color: #008CBA;
}

.register-option-section {
    width: 100%;
    /* background-color: red; */
}

.individual-company-text-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    padding: 0px 10px;
}

.individual-company-text-view h3 {
    font-size: 56px;
    margin: 0px;
    color: #4a4545;
}

.individual-company-text-view button {
    width: 170px;
    background-color: #f2f2f2;
    height: 50px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    color: #4a4545;
    font-weight: 600;
}

.individual-company-text-view button:focus {
    background-color: #4c4343 !important;
    color: #fff !important;
}

.active-color {
    background-color: #4c4343 !important;
    color: #fff !important;
}

.or-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
}

.or-option .horizantal-line {
    width: 40%;
    height: 1px;
    background-color: #333;
}

.or-title h4 {
    font-size: 20px;
    color: #4a4545;
}

.individual-button-view {
    margin-top: 10px;
}

.individual-button-view a {
    background-color: #f2f2f2;
    width: 100%;
    border: none;
    margin: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 26px;
    color: #4a4545;
    font-weight: 600;
}

.login-text-title-view {
    padding: 0px 10px;
    margin-bottom: 40px;
}

.login-text-title-view h3 {
    font-size: 26px;
    text-align: center;
    color: #383838;
}

.login-form-container {
    margin: 20px 0px;
    /* text-align: center; */
}

.form-group-item {
    margin-bottom: 20px;
}

.form-group-item label {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #332d28;
    padding: 0px 0px 7px 0px;
}

.input-control {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 0px 10px 0px 20px;
}

.select-control {
    height: 45px !important;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 0px 10px 0px 20px;
}

.textarea-control {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 10px 10px 0px 20px;
}

.login-option-section {
    width: 100%;
}

.auth-btn-view {
    display: flex;
    justify-content: center;
}

.login-btn {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 6px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn span {
    margin-left: 5px;
}

.input-control-merge input {
    border-right-color: transparent;
}

.phone-number-container {
    display: flex;
    border-radius: 6px;
    border: 1px solid #989898;
    height: 45px;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ffffff;
    position: relative;
}

.tooltip-info {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid #6e6e6e;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6e6e6e;
    position: absolute;
    top: 11px;
    right: -30px;
    cursor: pointer;
}

.tooltip-info .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #332d28;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    /* top: -5px; */
    right: -210px;
}

.tooltip-info .tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #332d28 transparent transparent;
}

.tooltip-info:hover .tooltip-text {
    visibility: visible;
}

.country-code-control {
    width: 60px;
    border: none;
    height: 30px;
    font-size: 17px;
    border-right: 1px solid #989898;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    color: #6e6e6e;
}

.country-code-control:disabled {
    background-color: transparent;
}

.phone-control {
    flex: 1;
    border: none;
    height: 30px;
    font-size: 16px;
    border-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-left: 10px;
    color: #6e6e6e;
}

.input-control-text {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #989898;
    border-radius: 0.25rem;
    border-left-color: transparent;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.input-control-text i.fa {
    font-size: 20px;
}

.check-input-control {
    margin-bottom: 20px;
}

.check-input-control label {
    color: #2f2b2b;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    display: block;
}

.check-input-control label a {
    color: #ee7b22;
}

.social-media-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.social-media-item {
    width: 160px;
    background-color: #332d28;
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 5px;
    color: #ffff;
    font-size: 16px;
    border: none;
}

.social-media-img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-right: 5px;
}

.have-account-view {
    margin: 30px 0px 20px 30px;
}

.have-account-view p {
    color: #332d28;
    text-align: center;
}

.have-account-view p a {
    color: #ee7b22;
    font-size: 14px;
}

.purchash-section {
    width: 100%;
}

.purchash-title-view {
    text-align: center;
}

.purchash-title-view h3 {
    font-size: 26px;
    color: #383838;
    padding-top: 40px;
}

.purchash-title-view h4 {
    font-size: 16px;
    color: #383838;
    padding-top: 25px;
    margin: 0px;
}

.purchash-title-view h2 {
    font-size: 80px;
    color: #383838;
    font-weight: 600;
    margin: 0px;
    position: relative;
}

.purchash-title-view h2 span {
    position: absolute;
}

.purchash-title-view h5 {
    font-size: 18px;
    color: #383838;
    padding-bottom: 20px;
}

.purchash-item-view {
    width: 100%;
}

.purchash-item-view button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: 6px;
    height: 50px;
    padding: 0px 30px;
    margin-bottom: 20px;
    border-width: 1px;
    border-color: #989898;
}

.purchash-item-view span {
    font-size: 18px;
    font-weight: 600;
    color: #495057;
}

.purchash-btn-view {
    padding: 10px;
    text-align: center;
}

.purchash-btn {
    background-color: #383838;
    border: none;
    color: #ffffff;
    padding: 10px 50px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    margin: auto;
}

.purchash-btn:hover {
    border-color: transparent !important;
}

.purchash-btn-view p {
    font-size: 13px;
    padding: 30px 0px 40px 0px;
    text-align: center;
}

.active-btn {
    border: 1px solid #ee7b22 !important;
}

.active-btn span {
    color: #000000;
}

.purchash-item-view button:hover {
    border: 1px solid #ee7b22 !important;
}

.set-profile-container {
    background-color: #eaeaea;
    border-radius: 20px;
    padding: 25px 25px;
    margin-bottom: 20px;
}

.set-profile-container h3 {
    font-size: 18px;
    color: #383838;
}

.set-profile-container p {
    font-size: 13px;
}

.set-profile-container h4 {
    padding-top: 10px;
    color: #383838;
    font-weight: 700;
    font-size: 18px;
    margin: 0px;
}

.set-profile-container h4 a {
    color: #383838;
}

.set-profile-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* margin-top: 20px; */
    padding: 0px 20px;
}

.set-profile-btn-container a {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #383838;
    border-radius: 50px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #383838;
    margin-top: 20px;
}

.set-profile-back-btn {
    background-color: transparent !important;
    color: #383838 !important;
}

.set-profile-title-view {
    margin-bottom: 20px;
}

.set-profile-input-control {
    margin-top: 5px;
    margin-bottom: 0px;

}

.set-profile-input-control label {
    font-size: 14px;
    font-weight: 600;
}

.set-profile-input-control select {
    border: none;
    margin-top: 5px;
}

.confirm-form-group-item label {
    font-size: 15px;
    font-weight: 700;
    color: #332d28;
    padding: 0px 0px 7px 0px;
}

.confirm-form-group-item input {
    background-color: #eaeaea;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    padding-left: 20px;
}

.foot-label-text p {
    font-size: 14px;
    font-weight: 400;
    padding-top: 2px;
}

.setting-text-title-view {
    margin-top: 50px;
}

.setting-text-title-view h3 {
    font-size: 26px;
    text-align: center;
    color: #383838;
    margin: 0px;
}

.setting-text-title-view p {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.setting-check-view {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.setting-check-view p {
    margin: 0px;
    padding-left: 10px;
}

/* The container */
.setting-label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.setting-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.check-mark {
    position: absolute;
    top: -8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #636363;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.setting-label:hover input~.check-mark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.setting-label input:checked~.check-mark {
    background-color: #636363;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-mark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.setting-label input:checked~.check-mark:after {
    display: block;
}

/* Style the checkmark/indicator */
.setting-label .check-mark:after {
    left: 10px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.edit-form-setting {
    position: relative;
}

.edit-icon-form-btn {
    text-decoration: none;
    position: absolute;
    bottom: 5px;
    right: 10px;
    padding: 5px 8px;
}

.edit-icon-form-btn i.fa {
    color: #989898;
    font-size: 20px;
}

.setting-btn {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 50px;
    padding: 15px 50px;
    font-size: 14px;
    font-weight: 600;
    border: none;
}

.select-drop-down {
    position: relative;
}

.select-drop-down span {
    position: absolute;
    top: 15px;
    right: 15px;
}

.select-drop-down span i {
    font-size: 16px;
}

.congratulation-section {
    width: 100%;
    border: 2px solid #989898;
    padding: 50px 50px;
    text-align: center;
    border-radius: 10px;
}

.congratulation-section h2 {
    font-size: 36px;
}

.congratulation-section h3 {
    font-size: 15px;
    padding-bottom: 20px;
}

.congratulation-section button {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 6px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

/******************dashboard*******************/
#wrapper {
    background-color: #ede7e2;
}

.navbar-custom {
    background-color: transparent;
    box-shadow: none;
}

.logo-box {
    width: 280px;
    background-color: #332d28;
    padding: 0px 20px;
}

.dash-head-logo {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1.4px solid #fff;
}

.logo-box .logo:hover {
    text-decoration: none;
}

.dash-head-title p {
    color: #332d28;
    margin: 0px;
    padding-top: 20px;
    font-weight: 500;
    font-size: 22px;
    padding-left: 30px;
}

.left-side-menu {
    width: 280px;
    background-color: #332d28;
}

.content-page {
    margin-left: 280px;
}

.footer {
    left: 280px;
}

.border-top-menu {
    width: 100%;
    background-color: #858280;
    height: 1.6px;
}

.iagree-label a {
    text-decoration: underline;
}

.otp-reset-view p {
    color: #332d28;
    font-size: 13px;
}

.otp-reset-view p button {
    color: #ee7b22;
    border: none;
    background-color: transparent;
    font-weight: 700;
}

.otp-reset-view p button:disabled {
    opacity: 0.6;
}

.custom-checkbox {
    display: flex;
}

.custom-control-label {
    padding-left: 5px;
    color: #332d28;
    text-align: left;
}

.custom-control-label a {
    color: #ee7b22;
}

.form-check-input {
    border-color: #332d28;
}

.form-check-input:checked {
    background-color: #332d28;
    border-color: #332d28;
}

/***************side-nav-bar-profile-view******************/
.nav-profile {
    margin-top: 5px;
    padding-left: 20px;
    padding-bottom: 10px;
}

.nav-profile-img {
    display: flex;
    align-items: center;
}

.nav-profile-img-left img {
    width: 55px;
    height: 55px;
    border-radius: 10px;
}

.nav-profile-img-right {
    padding-left: 10px;
}

.nav-profile-img-right h5 {
    margin: 0px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
}

.nav-profile-img-right p {
    margin: 0px;
    font-size: 10px;
    color: #ffffff;
    font-weight: 100;
    line-height: 1;
}

.nav-profile-menu {
    margin-top: 5px;
}

.nav-profile-menu ul {
    margin: 0px;
}

.nav-profile-menu ul li {
    list-style: none;
}

.nav-profile-menu ul li a {
    padding: 10px 0px;
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
}

.nav-profile-menu ul .pp-active {
    background-color: #514b46;
}

/*****************************switch-button*****************/
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #332d28;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ee7b22;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ee7b22;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 40px;
}

.slider.round:before {
    border-radius: 50%;
}

.edit-profile-view {
    width: 100%;
    margin-top: 10px;
}

.edit-profile-view a {
    background-color: #ff9a55;
    display: block;
    text-align: center;
    color: #fff;
    padding: 10px 0px;
    font-size: 15px;
}

/************************global-loading*****************/
.loading-view {
    width: 100%;
    height: 100vh;
    background-color: #00000063;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon-view {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*****************************Role-management********************/
.add-role-button-view {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.role-form-btn {
    background-color: #332d28;
    border-color: #332d28;
    padding: 10px 40px;
    margin-left: 10px;
}

.role-form-btn:hover,
.role-form-btn:focus {
    background-color: #332d28;
    border-color: #332d28;
    opacity: 0.6;
}

/*******************Modal-view******************/
.add-user-modal-form-view {
    padding: 30px 30px;
}

.modal-title-view {
    text-align: center;
    padding: 0px 20px;
}

.modal-title-view p {
    font-size: 12px;
}

.form-group-modal-custom label {
    font-size: 14px;
    font-weight: 400;
}

.form-group-modal-custom input,
.form-group-modal-custom select {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px;
    font-size: 14px;
}

.form-group-modal-custom input:hover,
.form-group-modal-custom input:focus,
.form-group-modal-custom select:hover,
.form-group-modal-custom select:focus {
    background-color: #f2f2f2;
    border-color: #989898;
    border-radius: 10px;
}

.modal-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-cencel-btn {
    background-color: #ffffff;
    border-color: #332d28;
    color: #332d28;
    margin-right: 10px;
    padding: 10px 30px;
}

.sub-checkMark {
    margin-left: 25px;
}

.select-custom-form {
    background-color: red;
}

.phone-number-form-group {
    display: flex;
    background-color: #f2f2f2;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.phone-number-form-group .phone-control {
    background-color: transparent;
    color: #6e6e6e;
    font-size: 14px;
}

/****************react-select************/

.css-13cymwt-control {
    height: 45px;
    border-radius: 6px !important;
    border: 1px solid #989898 !important;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
}

.css-t3ipsp-control {
    height: 45px;
    border-radius: 6px !important;
    border: 1px solid #008CBA !important;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

#editCountry .css-13cymwt-control,
#editState .css-13cymwt-control,
#editCity .css-13cymwt-control {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px !important;
    padding: 0.375rem 0rem;
    font-size: 14px;
    border-color: transparent !important;
}

#editCountry .css-qbdosj-Input,
#editState .css-qbdosj-Input,
#editCity .css-qbdosj-Input {
    color: #6e6e6e !important;
}

#editCountry .css-t3ipsp-control,
#editState .css-t3ipsp-control,
#editCity .css-t3ipsp-control {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px !important;
    padding: 0.375rem 0rem;
    font-size: 14px;
    border-color: transparent !important;
    border: 1px solid #989898 !important;
    box-shadow: none !important;
    color: red !important;
}

#editCountry .css-1u9des2-indicatorSeparator,
#editState.css-1u9des2-indicatorSeparator,
#editCity .css-1u9des2-indicatorSeparator {
    display: none;
}

.css-tj5bde-Svg {
    color: #6e6e6e !important;
}

/********************profile*****************/
.profile-view-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.user-profile-thum {
    width: 100px;
    height: 100px;
    position: relative;
    text-align: center;
    border: 2px solid gray;
    border-radius: 50px;
    padding: 2px;
}

.user-profile-thum img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.custom-file-input {
    position: absolute;
    right: -5px;
    z-index: 9;
    bottom: -5px;
}

.custom-file-input label {
    color: white;
    background-color: #ff9a55;
    font-family: sans-serif;
    border-radius: 50px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}